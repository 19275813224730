@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  height: 100%;
}

#root {
  height: 100%;
}

.activeTab {
  background-color: #fff5f5;
  color: #f44336;
}

.activeheader {
  color: #f44336;
}

input.PhoneInputInput {
  height: 50px;
}

input.PhoneInputInput:focus-visible {
  border: none !important;
}

*:focus {
  outline: none;
}

.activeColor {
  background-color: #f44336;
}

.activeTextColor {
  color: #f44336;
}

.popup-content {
  width: 28% !important;
}

/* popup media screen */
@media (min-width: 1025px) and (max-width: 768px) {
  .popup-content {
    width: 50% !important;
  }
}

@media screen and (max-width: 768px) {
  .popup-content {
    width: 70% !important;
  }
}

.chat-message input {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 48px;
  margin-bottom: 4px;

  background: #ffffff;
  border: 1px solid #e8e9f0;
  box-sizing: border-box;
  border-radius: 4px;
}

.send-message {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 10px 12px 14px;

  width: 24px;
  height: 24px;

  background: #da1a5b;
  border-radius: 4px;
}

.arrow {
  position: static;
  width: 24px;
  height: 24px;
  left: 38px;
  top: 12px;

  transform: rotate(90deg);
}

.arrow path {
  fill: #fff;
}


.centerFlex{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; 

}